$(document).ready(function () {



    // Prevent default event action
    // ----------------------------------------

    function preventDefaultAction(e) {
        e.preventDefault ? e.preventDefault() : (event.returnValue = false);
    }


    // Version for visually impaired
    // ----------------------------------------

    (function () {

        $('body').on('click', '*[data-html-behaviour="toggle-version"]', function (e) {
            preventDefaultAction(e);
            var version = $(this).attr('data-html-version');
            saveSelectedVersion(version);
            switchClasses(version);
        });

        function saveSelectedVersion(version) {
            switch (version) {
                case 'normal':
                    Cookies.set('html_version', 'normal');
                    break;
                case 'vi':
                    Cookies('html_version', 'vi');
                    break;
            }
        }

        function switchClasses(version) {
            var $html = $('html');
            $html.removeClass('html_version_vi');
            $html.removeClass('html_version_normal');

            switch (version) {
                case 'normal':
                    $html.addClass('html_version_normal');
                    break;
                case 'vi':
                    $html.addClass('html_version_vi');
                    break;
            }
        }

    })();


    // Main banner slider

    var slider = tns({
        container: '*[data-main-banner]',
        items: 1,
        controls: false,
        navPosition: 'bottom',
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayHoverPause: true,
        fixedWidth: 600
    });


    // Init news masonry layout

    var $grid = $('*[data-news-list-masonry]').masonry({
        "gutter": 30,
        "columnWidth": 285
    });
    $grid.imagesLoaded().progress( function() {
      $grid.masonry('layout');
    });


    // Search switch

    (function () {

        $('body').on('click', '*[data-search-switch]', function (e) {
            preventDefaultAction(e);
            var $allSwitches = $('*[data-search-switch]');
            $allSwitches.removeClass('b-search__switch-link_active');
            $(this).addClass('b-search__switch-link_active');
            var $allTargets = $('*[data-search-target]');
            var $chosenTarget = $('*[data-search-target="' + $(this).attr('data-search-switch') + '"]');
            $allTargets.hide();
            $chosenTarget.show();
        });

    })();

});